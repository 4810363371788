.input-number{
  width: 55px;
  padding: 0 12px;
  vertical-align: top;
  text-align: center;
  outline: none;
}

.input-number,
.input-number-decrement,
.input-number-increment{
  border: 1px solid #ccc;
  height: 30px;
  user-select: none;
}

.input-number-decrement,
.input-number-increment{
  display: inline-block;
  width: 20%;
  line-height: 38px;
  background: #f1f1f1;
  color: #444;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

&:active{
  background: #ddd;
}
.input-number-decrement{
  border-right: none;
  /* border-radius: 4px 0 0 4px; */
}

.input-number-increment{
  border-left: none;
  /* border-radius: 0 4px 4px 0; */
}

.input-number-decrement.input-number-decrement-xs {
  height: 30px;
  line-height: 30px;
}

.input-number-increment.input-number-increment-xs {
  height: 30px;
  line-height: 30px;
}
